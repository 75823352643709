@use "sass:color";
@use "./_fonts";

@import "~/node_modules/bulma/sass/utilities/initial-variables.sass";
@import "~/node_modules/bulma/sass/utilities/functions.sass";

$black: #0f0f0f;
$grey-darker: #11151c;
$grey-light: #dddddd;
$white: #ffffff;

$red: #dc322f;
$orange: #d26937;
$yellow: #b58900;
$blue: #195466;
$cyan: #599cab;
$green: #2aa889;

$primary: #99d1ce;
$link: $green;
$link-hover: color.scale($green, $lightness: +10%);
$link-active: color.scale($green, $lightness: +10%);
$link-focus: color.scale($green, $lightness: +10%);
$input-color: $grey-light;
$input-placeholder-color: $grey-light; // Some opacity is applied to this

$weight-normal: 400;

$scheme-main: $black;

$family-sans-serif: Nunito, $family-sans-serif;
$family-monospace: Hack, $family-monospace;

$text: $grey-light;
$text-strong: $primary;
$label-color: $text;

$content-heading-color: $text;
$hr-background-color: $grey-light;
$hr-height: 1px;

$pre-background: $grey-darker;

@import "~/node_modules/bulma";
@import "./_navbar";
