@import "./_custom-bulma";
@import "./_typed";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#typed-welcome {
  &::before {
    @include typed("Welcome to tlater.net!", 1.2s);
  }

  &::after {
    @include cursor(6s);
  }
}
