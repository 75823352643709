@use "~/node_modules/@fontsource/nunito/scss/mixins" as Nunito;
@use "~/node_modules/@fontsource/arimo/scss/mixins" as Arimo;

@include Nunito.faces(
  $weights: (
    300,
    400,
    500,
    600,
    700,
  ),
  $display: auto,
  $styles: (
    normal,
    italic,
  ),
  $directory: "npm:@fontsource/nunito/files"
);

@include Arimo.faces(
  $weights: 400,
  $display: auto,
  $styles: normal,
  $directory: "npm:@fontsource/arimo/files"
);

// Hack *does* come with its own CSS, but it's broken and hasn't seen
// a release since https://github.com/source-foundry/Hack/issues/467
// was resolved.

$variants: regular 400 normal, bold 700 normal, italic 400 italic,
  bolditalic 700 italic;

@each $name, $weight, $style in $variants {
  @font-face {
    font-family: "Hack";
    src: url("npm:hack-font/build/web/fonts/hack-#{$name}-subset.woff2?sha=3114f1256")
        format("woff2"),
      url("npm:hack-font/build/web/fonts/hack-#{$name}-subset.woff?sha=3114f1256")
        format("woff");
    font-weight: $weight;
    font-style: $style;
  }
}
