.navbar.is-dark {
  border: 1px solid #000000;

  & .navbar-brand > .navbar-item {
    font-family: Arimo;

    &:hover {
      background-color: $dark !important;
      color: $white !important;
    }
  }

  & .navbar-start > .navbar-item {
    color: rgba($white, 0.75);

    &:hover {
      background-color: $dark !important;
      color: $white !important;
    }
  }
}
